import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import InfoCard from "../components/infocard";
import { nanoid } from "nanoid";

const data = [
  {
    title: "Общая информация об образовательных стандартах организации",
    link: "",
    description:
      'В соответствии с Приказом Минобрнауки РФ от 01.07.2013 № 499 "Об утверждении порядка организации и осуществления образовательной деятельности по дополнительным профессиональным программам":',

    paragraphs: [
      'п. 8. Содержание реализуемой дополнительной профессиональной программы должно учитывать профессиональные стандарты, квалификационные требования, указанные в квалификационных справочниках по соответствующим должностям, профессиям и специальностям, или квалификационные требования к профессиональным знаниям и навыкам, необходимым для исполнения должностных обязанностей, которые устанавливаются в соответствии с федеральными законами и иными нормативными правовыми актами Российской Федерации (п. 9. ст. 76 Федерального закона от 29.12.2012 № 273-ФЗ "Об образовании в Российской Федерации").',
      'п. 10. Программа профессиональной переподготовки разрабатывается организацией на основании установленных квалификационных требований, профессиональных стандартов и требований соответствующих федеральных государственных образовательных стандартов среднего профессионального и (или) высшего образования к результатам освоения образовательных программ (п. 10. ст. 76 Федерального закона от 29.12.2012 № 273-ФЗ "Об образовании в Российской Федерации").',
    ],
  },
  {
    title: "ФГОС среднего профессионального образования",
    link: "http://fumo-spo.ru/?p=articles&show=4",
    description: "",
    paragraphs: [
      "Портал федеральных учебно-методических объединений в среднем профессиональном образовании / Актуализированные федеральные государственные образовательные стандарты среднего профессионального образования на 06.05.2018 ",
    ],
  },
  {
    title:
      "ФГОС высшего профессионального образования по направлениям подготовки бакалавриата",
    link: "http://fgosvo.ru/fgosvo/92/91/4",
    description: "",
    paragraphs: [
      "Портал Федеральных государственных образовательных стандартов высшего образования",
    ],
  },
  {
    title:
      'Справочная информация: "Федеральные государственные образовательные стандарты" (Материал подготовлен специалистами КонсультантПлюс)',
    link: "http://www.consultant.ru/document/cons_doc_LAW_142304/",
    description: "",
    paragraphs: [
      'Справочная информация: "Федеральные государственные образовательные стандарты" ',
    ],
  },
  {
    title:
      'Справочная информация: "Единый квалификационный справочник должностей руководителей, специалистов и служащих" (Материал подготовлен специалистами КонсультантПлюс)',
    link: "http://www.consultant.ru/document/cons_doc_LAW_97378/",
    description: "",
    paragraphs: [
      'Справочная информация: "Единый квалификационный справочник должностей руководителей, специалистов и служащих" (Материал подготовлен специалистами КонсультантПлюс)',
    ],
  },
  {
    title:
      'Справочная информация: "Профессиональные стандарты" (Материал подготовлен специалистами КонсультантПлюс)',
    link: "http://www.consultant.ru/document/cons_doc_LAW_157436/",
    description: "",
    paragraphs: [
      "Профессиональный стандарт - характеристика квалификации, необходимой работнику для осуществления определенного вида профессиональной деятельности, в том числе выполнения определенной трудовой функции.",
    ],
  },
  {
    title: "Реестр профессиональных стандартов",
    link: "http://profstandart.rosmintrud.ru/obshchiy-informatsionnyy-blok/natsionalnyy-reestr-professionalnykh-standartov/reestr-professionalnykh-standartov/",
    description: "",
    paragraphs: ["Реестр профессиональных стандартов"],
  },
  {
    title: "Справочник профессий",
    link: "http://spravochnik.rosmintrud.ru/professions",
    description: "",
    paragraphs: [
      "Ресурс Минтруда России по профессиям в формате поискового комплекса. ",
    ],
  },
];

function EduStandrts() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Образовательные стандарты`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title="Образование"
      />

      <section className="flex flex-col items-center ">
        <h1 className=" text-center text-2xl uppercase tracking-wider text-gray-800 font-bold mb-4">
          Образовательные стандарты
        </h1>
        {data.map((card) => (
          <InfoCard key={nanoid()} params={card} />
        ))}
      </section>
    </Layout>
  );
}

export default EduStandrts;
