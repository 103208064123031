import React from "react";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";

const InfoCard = ({ params }) => {
  const { title, paragraphs, link, description } = params;
  return (
    <div className="my-2 bg-white rounded shadow w-full">
      <div className="p-2 bg-green-300 ">
        <p className="my-2 px-4">{title}</p>
      </div>
      <hr className="" />
      <div className="p-4">
        {!description ? null : <p className="font-bold my-4">{description}</p>}
        {!link ? null : (
          <div>
            <h4 className="mb-2  font-bold">Ссылка на документы:</h4>
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="py-2 px-4  bg-blue-600 hover:bg-blue-400 text-white rounded shadow-lg hover:shadow-none inline-block"
            >
              Перейти
            </a>

            <p className="font-bold mt-4">Описание:</p>
          </div>
        )}
        {!paragraphs
          ? null
          : paragraphs.map((par) => (
              <p key={nanoid()} className="my-2">
                {par}
              </p>
            ))}
      </div>
    </div>
  );
};

InfoCard.propTypes = {
  params: PropTypes.object.isRequired,
};

export default InfoCard;
